"use strict";

var _interopRequireDefault = require("/Users/sophiechou/Desktop/whenyoung/\u5609\u5584\u51CF\u707E\u793E\u533A/admin-web/node_modules/.pnpm/@babel+runtime@7.24.8/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/sophiechou/Desktop/whenyoung/\u5609\u5584\u51CF\u707E\u793E\u533A/admin-web/node_modules/.pnpm/@babel+runtime@7.24.8/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.object.entries.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));
var _jsBase = require("js-base64");
var _utils = require("@/utils");
// 请求添加条件，如token
_axios.default.interceptors.request.use(function (config) {
  var isToken = config.headers["X-isToken"] === false ? config.headers["X-isToken"] : true;
  var token = _localstorage.default.get("TOKEN", "");
  if (token && isToken) {
    config.headers.token = "Bearer " + token;
  }
  var isTenant = config.headers["X-isTenant"] === false ? config.headers["X-isTenant"] : true;
  if (isTenant && process.env.VUE_APP_IS_MULTI_TENANT_TYPE !== "NONE") {
    config.headers.tenant = _localstorage.default.get("TENANT", "");
  }
  var clientId = process.env.VUE_APP_CLIENT_ID;
  var clientSecret = process.env.VUE_APP_CLIENT_SECRET;
  config.headers["Authorization"] = "Basic ".concat(_jsBase.Base64.encode("".concat(clientId, ":").concat(clientSecret)));
  return config;
}, function (error) {
  return Promise.reject(error);
});

// 接口返回处理
_axios.default.interceptors.response.use(function (response) {
  var res = response;
  if (response.config.responseType !== "blob") {
    res = (0, _utils.desensitizationData)(response);
  }
  return res;
}, function (error) {
  return Promise.reject(error);
});
function handleError(error, reject, opts) {
  var isAlert = opts.custom ? opts.custom["isAlert"] : true;
  isAlert = isAlert === undefined ? true : isAlert;
  if (isAlert) {
    if (error.code === "ECONNABORTED") {
      (0, _elementUi.Message)({
        message: "请求超时",
        customClass: "msgError"
      });
    } else if (error.response && error.response.data) {
      if (error.response.status === 500) {
        (0, _elementUi.Message)({
          customClass: "msgError",
          message: error.response.data
        });
      } else {
        var resData = error.response.data;
        if (resData.code === 40000 || resData.code === 40001 || resData.code === 40002 || resData.code === 40003 || resData.code === 40005 || resData.code === 40006 || resData.code === 40008 || resData.code === 40009) {
          _elementUi.MessageBox.alert(resData.msg || resData.message, "提醒", {
            confirmButtonText: "确定",
            callback: function callback() {
              _localstorage.default.clear();
              window.location.hash = "/login";
            }
          });
        } else if (resData.msg) {
          (0, _elementUi.Message)({
            customClass: "msgError",
            message: resData.msg
          });
        } else if (resData.message) {
          (0, _elementUi.Message)({
            customClass: "msgError",
            message: resData.message
          });
        }
      }
    } else if (error.message) {
      (0, _elementUi.Message)({
        customClass: "msgError",
        message: error.message
      });
    }
  }
  reject(error);
}
function handleSuccess(res, resolve, opts) {
  var isAlert = opts.custom ? opts.custom["isAlert"] : true;
  isAlert = isAlert === undefined ? true : isAlert;
  var resData = res.data;
  if (resData.isSuccess === false) {
    // 未登录
    if (resData.code === 40000 || resData.code === 40001 || resData.code === 40002 || resData.code === 40003 || resData.code === 40005 || resData.code === 40006 || resData.code === 40008) {
      // debugger
      _elementUi.MessageBox.alert(resData.msg, "提醒", {
        confirmButtonText: "确定",
        callback: function callback() {
          window.location.hash = "/login";
        }
      });
    } else {
      if (isAlert) {
        _elementUi.Message.error(resData.msg);
      }
    }
  }
  resolve(res);
}

// http请求
var httpServer = function httpServer(opts) {
  // 公共参数
  var publicParams = {
    ts: Date.now()
  };

  // http默认配置
  var method = opts.method.toUpperCase();
  // baseURL
  // 开发环境： /api                 // 开发环境在 vue.config.js 中有 devServer.proxy 代理
  // 生产环境： http://IP:PORT/api   // 生产环境中 代理失效， 故需要配置绝对路径

  var baseUrl = process.env.VUE_APP_DEV_REQUEST_DOMAIN_PREFIX;
  var protocol = window.location.protocol;
  var host = window.location.host;
  var hostname = window.location.hostname;
  var port = window.location.port;
  if (process.env.NODE_ENV == "development" || process.env.NODE_ENV == "test") {
    baseUrl = "".concat(protocol, "//").concat(host).concat(process.env.VUE_APP_BASE_API);
  } else if (process.env.NODE_ENV == "production") {
    baseUrl = "".concat(protocol, "//").concat(host).concat(process.env.VUE_APP_BASE_API);
  }
  // ${process.env.VUE_APP_BASE_API}
  var httpDefaultOpts = {
    method: method,
    baseURL: baseUrl,
    url: opts.url,
    responseType: opts.responseType || "",
    timeout: opts.custom && opts.custom["timeout"] || 30000
  };
  if (opts["meta"]) {
    httpDefaultOpts.headers = opts["meta"];
  }
  var dataRequest = ["PUT", "POST", "PATCH"];
  if (dataRequest.includes(method)) {
    if (opts.params) {
      httpDefaultOpts.params = opts.params || {};
    } else {
      httpDefaultOpts.data = opts.data || {};
    }
  } else {
    httpDefaultOpts.params = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, publicParams), opts.data || {});
  }

  // formData转换
  if (opts.formData) {
    httpDefaultOpts.transformRequest = [function (data) {
      var formData = new FormData();
      if (data) {
        Object.entries(data).forEach(function (item) {
          formData.append(item[0], item[1]);
        });
      }
      return formData;
    }];
  }
  var promise = new Promise(function (resolve, reject) {
    (0, _axios.default)(httpDefaultOpts).then(function (response) {
      handleSuccess(response, resolve, opts);
    }).catch(function (error) {
      handleError(error, reject, opts);
    });
  });
  return promise;
};
var _default = exports.default = httpServer;