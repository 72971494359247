"use strict";

var _interopRequireDefault = require("/Users/sophiechou/Desktop/whenyoung/\u5609\u5584\u51CF\u707E\u793E\u533A/admin-web/node_modules/.pnpm/@babel+runtime@7.24.8/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AxiosApi = _interopRequireDefault(require("./AxiosApi.js"));
var apiList = {
  captcha: "/anno/captcha",
  // login: `/noToken/login`,
  login: "/noToken/login/captcha",
  logout: "/noToken/logout",
  router: "/menu/router",
  resource: "/resource/visible",
  setPhone: "/",
  getTenantCode: "/user/getTenantCode",
  parameterInfo: "/noToken/parameterInfo"
};
var _default = exports.default = {
  getCaptcha: function getCaptcha(randomId) {
    return (0, _AxiosApi.default)({
      method: "GET",
      url: apiList.captcha + "?key=".concat(randomId),
      responseType: "arraybuffer",
      meta: {
        "X-isToken": false
      }
    });
  },
  login: function login(data, meta) {
    return (0, _AxiosApi.default)({
      method: "POST",
      url: apiList.login,
      data: data,
      meta: meta
    });
  },
  sendCodeByaccount: function sendCodeByaccount(params) {
    return (0, _AxiosApi.default)({
      method: "POST",
      url: "/noToken/sendCodeByaccount",
      meta: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      params: params
    });
  },
  logout: function logout(data, meta) {
    return (0, _AxiosApi.default)({
      method: "POST",
      formData: true,
      url: apiList.logout,
      data: data,
      meta: meta
    });
  },
  getRouter: function getRouter(data) {
    return (0, _AxiosApi.default)({
      method: "GET",
      url: apiList.router,
      data: data || {}
    });
  },
  getResource: function getResource(data) {
    return (0, _AxiosApi.default)({
      method: "GET",
      url: apiList.resource,
      data: data || {}
    });
  },
  setPhone: function setPhone(data, meta) {
    return (0, _AxiosApi.default)({
      method: "POST",
      url: apiList.setPhone,
      data: data,
      meta: meta
    });
  },
  getTenantCode: function getTenantCode(account) {
    return (0, _AxiosApi.default)({
      method: "GET",
      url: apiList.getTenantCode + "?account=".concat(account)
    });
  },
  parameterInfo: function parameterInfo(key) {
    return (0, _AxiosApi.default)({
      method: "GET",
      url: apiList.parameterInfo + "?key=".concat(key)
    });
  }
};