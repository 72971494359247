// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"menuText": "rgba(255, 255, 255, 0.7)",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#fff",
	"menuBg": "#393e46",
	"menuHover": "#313131",
	"subMenuBg": "#393e46",
	"subMenuHover": "#313131",
	"sideBarWidth": "235px",
	"menuTextHover": "#fff"
};
module.exports = exports;
