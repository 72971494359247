"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
//
//
//
//
//
//
var _default = exports.default = {
  name: "App",
  created: function created() {
    //TODO 每次刷新页面会加载这个方法？是否能在这里判断 必须要缓存的Token ，用户信息等
    var that = this;
    window.showShelterInfo = function (id) {
      console.log(id, 'id');
      that.$router.push({
        path: "/place/list/detail",
        query: {
          id: id + ''
        }
      });
    };
    window.showImgInfo = function (url) {
      // that
      console.log(url, 'url');
      window.open(url);
    };
  }
};