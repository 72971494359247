"use strict";

var _interopRequireDefault = require("/Users/sophiechou/Desktop/whenyoung/\u5609\u5584\u51CF\u707E\u793E\u533A/admin-web/node_modules/.pnpm/@babel+runtime@7.24.8/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.starts-with.js");
require("core-js/modules/es.string.trim.js");
var _i18n = require("@/utils/i18n");
var _pathToRegexp = _interopRequireDefault(require("path-to-regexp"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      levelList: null
    };
  },
  watch: {
    $route: function $route(route) {
      // if you go to the redirect page, do not update the breadcrumbs
      if (route.path.startsWith("/redirect/")) {
        return;
      }
      this.getBreadcrumb();
    }
  },
  created: function created() {
    this.getBreadcrumb();
  },
  methods: {
    generateTitle: _i18n.generateTitle,
    getBreadcrumb: function getBreadcrumb() {
      // only show routes with meta.title
      var matched = this.$route.matched.filter(function (item) {
        return item.meta && item.meta.title;
      });
      var first = matched[0];
      if (!this.isDashboard(first)) {
        matched = [{
          path: "/dashboard",
          meta: {
            title: "dashboard"
          }
        }].concat(matched);
      }
      this.levelList = matched.filter(function (item) {
        return item.meta && item.meta.title && item.meta.breadcrumb !== false;
      });
      // console.log(this.levelList);
    },
    isDashboard: function isDashboard(route) {
      var name = route && route.name;
      if (!name) {
        return false;
      }
      return name.trim().toLocaleLowerCase() === "Dashboard".toLocaleLowerCase();
    },
    pathCompile: function pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      var params = this.$route.params;
      var toPath = _pathToRegexp.default.compile(path);
      return toPath(params);
    },
    handleLink: function handleLink(item) {
      var redirect = item.redirect,
        path = item.path;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(this.pathCompile(path));
    }
  }
};