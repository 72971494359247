"use strict";

var _interopRequireDefault = require("/Users/sophiechou/Desktop/whenyoung/\u5609\u5584\u51CF\u707E\u793E\u533A/admin-web/node_modules/.pnpm/@babel+runtime@7.24.8/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/sophiechou/Desktop/whenyoung/\u5609\u5584\u51CF\u707E\u793E\u533A/admin-web/node_modules/.pnpm/@babel+runtime@7.24.8/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _AxiosApi = _interopRequireDefault(require("./AxiosApi.js"));
var apiList = {
  page: {
    url: "/msg/msg/page",
    method: 'POST'
  },
  mark: {
    url: "/msg/msg/mark",
    method: 'GET'
  },
  save: {
    url: "/msg/msg",
    method: 'POST'
  },
  delete: {
    url: "/msg/msg",
    method: 'DELETE'
  },
  preview: {
    method: 'POST',
    url: "/msg/msg/preview"
  },
  export: {
    method: 'POST',
    url: "/msg/msg/export"
  },
  import: {
    method: 'POST',
    url: "/msg/msg/import"
  },
  // 分页获取通知管理列表
  notifyList: {
    method: 'POST',
    url: "/notice/noticeList"
  },
  // 创建通知
  createNotice: {
    method: 'POST',
    url: "/notice/createNotice"
  },
  // 删除公告
  deleteNotice: {
    method: 'POST',
    url: "/notice/deleteNotice"
  },
  // 修改公告
  updateNotice: {
    method: 'POST',
    url: "/notice/updateNotice"
  },
  // 发布公告
  publishNotice: {
    method: 'POST',
    url: "/notice/publishNotice"
  },
  // 撤回公告

  recallNotice: {
    method: 'POST',
    url: "/notice/recallNotice"
  },
  // navbar铃铛 数据

  messageAlertList: {
    method: 'POST',
    url: "/msg/msgReceive/messageAlertList"
  },
  notifyMark: {
    url: "/msg/notify/mark",
    method: 'POST'
  },
  notifySave: {
    url: "/msg/notify/add",
    method: 'POST'
  },
  notifyUpdate: {
    url: "/msg/notify/update",
    method: 'POST'
  },
  notifyDelete: {
    url: "/msg/notify/delete",
    method: 'POST',
    meta: {
      'Content-Type': "application/x-www-form-urlencoded"
    }
  }
};
var _default = exports.default = {
  page: function page(data) {
    var custom = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return (0, _AxiosApi.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, apiList.page), {}, {
      data: data,
      custom: custom
    }));
  },
  save: function save(data) {
    return (0, _AxiosApi.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, apiList.save), {}, {
      data: data
    }));
  },
  delete: function _delete(data) {
    return (0, _AxiosApi.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, apiList.delete), {}, {
      data: data
    }));
  },
  mark: function mark(data) {
    return (0, _AxiosApi.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, apiList.mark), {}, {
      data: data
    }));
  },
  get: function get(id) {
    return (0, _AxiosApi.default)({
      url: "/msg/msg/".concat(id),
      method: 'GET'
    });
  },
  preview: function preview(data) {
    return (0, _AxiosApi.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, apiList.preview), {}, {
      data: data
    }));
  },
  export: function _export(data) {
    return (0, _AxiosApi.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, apiList.export), {}, {
      responseType: "blob",
      data: data
    }));
  },
  import: function _import(data) {
    return (0, _AxiosApi.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, apiList.import), {}, {
      data: data
    }));
  },
  notifyList: function notifyList(data) {
    return (0, _AxiosApi.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, apiList.notifyList), {}, {
      data: data
    }));
  },
  createNotice: function createNotice(data) {
    return (0, _AxiosApi.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, apiList.createNotice), {}, {
      data: data
    }));
  },
  deleteNotice: function deleteNotice(data) {
    return (0, _AxiosApi.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, apiList.deleteNotice), {}, {
      data: data
    }));
  },
  updateNotice: function updateNotice(data) {
    return (0, _AxiosApi.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, apiList.updateNotice), {}, {
      data: data
    }));
  },
  publishNotice: function publishNotice(data) {
    return (0, _AxiosApi.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, apiList.publishNotice), {}, {
      data: data
    }));
  },
  recallNotice: function recallNotice(data) {
    return (0, _AxiosApi.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, apiList.recallNotice), {}, {
      data: data
    }));
  },
  messageAlertList: function messageAlertList(data) {
    return (0, _AxiosApi.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, apiList.messageAlertList), {}, {
      data: data
    }));
  },
  notifySave: function notifySave(data) {
    return (0, _AxiosApi.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, apiList.notifySave), {}, {
      data: data
    }));
  },
  notifyUpdate: function notifyUpdate(data) {
    return (0, _AxiosApi.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, apiList.notifyUpdate), {}, {
      data: data
    }));
  },
  getNotify: function getNotify(id) {
    return (0, _AxiosApi.default)({
      url: "/msg/notify/".concat(id),
      method: 'GET'
    });
  },
  notifyDelete: function notifyDelete(data) {
    return (0, _AxiosApi.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, apiList.notifyDelete), {}, {
      data: data
    }));
  },
  notifyMark: function notifyMark(data) {
    return (0, _AxiosApi.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, apiList.notifyMark), {}, {
      data: data
    }));
  }
};