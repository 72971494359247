"use strict";

var _interopRequireDefault = require("/Users/sophiechou/Desktop/whenyoung/\u5609\u5584\u51CF\u707E\u793E\u533A/admin-web/node_modules/.pnpm/@babel+runtime@7.24.8/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getExpireTime = getExpireTime;
exports.getRefreshToken = getRefreshToken;
exports.getToken = getToken;
var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));
function getToken() {
  return _localstorage.default.get('ACCESS_TOKEN', '');
}
function getRefreshToken() {
  return _localstorage.default.get('REFRESH_TOKEN', '');
}
function getExpireTime() {
  return _localstorage.default.get('EXPIRE_TIME', 0);
}