"use strict";

var _interopRequireDefault = require("/Users/sophiechou/Desktop/whenyoung/\u5609\u5584\u51CF\u707E\u793E\u533A/admin-web/node_modules/.pnpm/@babel+runtime@7.24.8/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addClass = addClass;
exports.analyzeIDCard = analyzeIDCard;
exports.byteLength = byteLength;
exports.cleanArray = cleanArray;
exports.copyText = copyText;
exports.createUniqueString = createUniqueString;
exports.debounce = debounce;
exports.deepClone = deepClone;
exports.desensitizationData = desensitizationData;
exports.exportBlob = exportBlob;
exports.findTextColor = findTextColor;
exports.formatTime = formatTime;
exports.getBirth = getBirth;
exports.getFileType = getFileType;
exports.getQueryObject = getQueryObject;
exports.getSex = getSex;
exports.getTime = getTime;
exports.hasClass = hasClass;
exports.html2Text = html2Text;
exports.imageCompression = imageCompression;
exports.objectMerge = objectMerge;
exports.param = param;
exports.param2Obj = param2Obj;
exports.parseTime = parseTime;
exports.randomNum = randomNum;
exports.removeClass = removeClass;
exports.setLastStr = setLastStr;
exports.setTableHeight = setTableHeight;
exports.setTableScroll = setTableScroll;
exports.throttle = throttle;
exports.toggleClass = toggleClass;
exports.transferTime = void 0;
exports.uniqueArr = uniqueArr;
exports.uniqueByField = uniqueByField;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.find.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.constructor.js");
require("core-js/modules/es.regexp.dot-all.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.sticky.js");
require("core-js/modules/es.regexp.test.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.set.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/es.string.match.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/esnext.set.difference.v2.js");
require("core-js/modules/esnext.set.intersection.v2.js");
require("core-js/modules/esnext.set.is-disjoint-from.v2.js");
require("core-js/modules/esnext.set.is-subset-of.v2.js");
require("core-js/modules/esnext.set.is-superset-of.v2.js");
require("core-js/modules/esnext.set.symmetric-difference.v2.js");
require("core-js/modules/esnext.set.union.v2.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _typeof2 = _interopRequireDefault(require("/Users/sophiechou/Desktop/whenyoung/\u5609\u5584\u51CF\u707E\u793E\u533A/admin-web/node_modules/.pnpm/@babel+runtime@7.24.8/node_modules/@babel/runtime/helpers/typeof.js"));
var _exifJs = _interopRequireDefault(require("exif-js"));
var _elementUi = require("element-ui");
var _aes = require("./aes");
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  var format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  var date;
  if ((0, _typeof2.default)(time) === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + "月" + d.getDate() + "日" + d.getHours() + "时" + d.getMinutes() + "分";
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  var search = url.substring(url.lastIndexOf("?") + 1);
  var obj = {};
  var reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, function (rs, $1, $2) {
    var name = decodeURIComponent($1);
    var val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
function byteLength(str) {
  // returns the byte length of an utf8 string
  var s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xdc00 && code <= 0xdfff) i--;
  }
  return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
function cleanArray(actual) {
  var newArray = [];
  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
function param(json) {
  if (!json) return "";
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return "";
    return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
  })).join("&");
}

/**
 * @param {string} url
 * @returns {Object}
 */
function param2Obj(url) {
  var search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, " ") + '"}');
}

/**
 * @param {string} val
 * @returns {string}
 */
function html2Text(val) {
  var div = document.createElement("div");
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
function objectMerge(target, source) {
  if ((0, _typeof2.default)(target) !== "object") {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(function (property) {
    var sourceProperty = source[property];
    if ((0, _typeof2.default)(sourceProperty) === "object") {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  var classString = element.className;
  var nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += "" + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

/**
 * @param {string} type
 * @returns {Date}
 */
function getTime(type) {
  if (type === "start") {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;
  var later = function later() {
    // 据上一次触发时间间隔
    var last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
function deepClone(source) {
  if (!source && (0, _typeof2.default)(source) !== "object") {
    throw new Error("error arguments", "deepClone");
  }
  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && (0, _typeof2.default)(source[keys]) === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
function createUniqueString() {
  var timestamp = +new Date() + "";
  var randomNum = parseInt((1 + Math.random()) * 65536) + "";
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += " " + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
  }
}
function getFileType(filename) {
  var startIndex = filename.lastIndexOf(".");
  if (startIndex !== -1) {
    return filename.substring(startIndex + 1, filename.length).toLowerCase();
  } else {
    return "";
  }
}
function randomNum(len, radix) {
  var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  var uuid = [];
  radix = radix || chars.length;
  if (len) {
    // Compact form
    for (var i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
  } else {
    // rfc4122, version 4 form
    var r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (var _i = 0; _i < 36; _i++) {
      if (!uuid[_i]) {
        r = 0 | Math.random() * 16;
        uuid[_i] = chars[_i === 19 ? r & 0x3 | 0x8 : r];
      }
    }
  }
  return uuid.join("") + new Date().getTime();
}

//动态计算table最大高度
function setTableHeight() {
  var h = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 110;
  var className = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "tableBox";
  // 获取窗口 body 高度
  var bodyHeight = document.body.clientHeight;
  var ele = document.getElementsByClassName(className);
  if (!ele[0]) {
    return;
  }
  var pTop = ele[0].offsetParent.offsetTop;
  var height = bodyHeight - ele[0].offsetTop - pTop - h;
  return height;
  // return bodyHeight - ele[0].offsetTop - pTop - h;
}

//重置table表格 top  left
function setTableScroll(refTable, that) {
  that.$refs[refTable].$el.getElementsByClassName("el-table__body-wrapper")[0].scrollTop = 0;
  that.$refs[refTable].$el.getElementsByClassName("el-table__body-wrapper")[0].scrollLeft = 0;
}
// 函数防抖
function throttle(func, wait) {
  var timeout;
  return function () {
    var _arguments = arguments,
      _this = this;
    if (!timeout) {
      timeout = setTimeout(function () {
        timeout = null;
        func.call(_this, _arguments);
      }, wait);
    }
  };
}
// 格式化值为数字
// val
function setLastStr(val) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  console.log(val, "llval");
  var reg = new RegExp("^[0-9]*$");
  var vals = val.split("");
  var lastVal = val.charAt(val.length - 1);
  var lastInd = vals.length - 1;
  var _str = "";
  if (!reg.test(lastVal)) {
    vals[lastInd] = format;
    vals.forEach(function (item) {
      _str += item;
    });
  } else {
    _str = val;
  }
  return _str + format;
}

/**
 * 根据身份证号得到姓别和精确计算年龄
 */
function analyzeIDCard(IDCard) {
  var sexAndAge = {};
  //获取用户身份证号码
  var userCard = IDCard;
  //如果身份证号码为undefind则返回空
  if (!userCard) {
    return sexAndAge;
  }
  //获取性别
  if (parseInt(userCard.substr(16, 1)) % 2 == 1) {
    sexAndAge.sex = "M-男";
  } else if (parseInt(userCard.substr(16, 1)) % 2 == 0) {
    sexAndAge.sex = "W-女";
  } else {
    sexAndAge.sex = "N-未知";
  }
  //获取出生年月日
  //userCard.substring(6,10) + "-" + userCard.substring(10,12) + "-" + userCard.substring(12,14);
  var yearBirth = userCard.substring(6, 10);
  var monthBirth = userCard.substring(10, 12);
  var dayBirth = userCard.substring(12, 14);
  //获取当前年月日并计算年龄
  var myDate = new Date();
  var monthNow = myDate.getMonth() + 1;
  var dayNow = myDate.getDate();
  var age = myDate.getFullYear() - yearBirth;
  console.log({
    monthNow: monthNow
  }, {
    monthBirth: monthBirth
  }, {
    dayNow: dayNow
  }, {
    dayBirth: dayBirth
  });
  if (monthNow < monthBirth || monthNow == monthBirth && dayNow < dayBirth) {
    age--;
  }
  //得到年龄
  sexAndAge.age = age;
  sexAndAge.birthday = "".concat(yearBirth, "-").concat(monthBirth, "-").concat(dayBirth);
  //返回性别和年龄
  return sexAndAge;
}

/**
 * 文件下载 Blob
 *
 */

function exportBlob(data, option) {
  option = Object.assign({}, option);
}

/**
 * 转换字体颜色
 * @param {array} rgbArr rgb数组
 */
function resBgColor(rgbArr) {
  // 当color值大于128时,color值偏向255,即#ffffff,此时字体颜色应为#000000
  // 当color值小于128时,color值偏向0,即#000000,此时字体颜色应为#ffffff
  var color = 0.213 * rgbArr[0] + 0.715 * rgbArr[1] + 0.072 * rgbArr[2] > 255 / 2;
  return color ? "#000000" : "#ffffff";
}

//
/**
 * 根据背景颜色动态修改文字颜色
 * @param {string} colorValue #123456或者rgb(12,34,56)转为rgb数组[12,34,56]
 * @returns {string} #ffffff
 */
function findTextColor(colorValue) {
  // #123456或者rgb(12,34,56)转为rgb数组[12,34,56]
  var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  var that = colorValue;
  if (/^(rgb|RGB)/.test(that)) {
    // 处理rgb转为数组
    var aColor = that.replace(/(?:\(|\)|rgb|RGB)*/g, "").split(",");
    return resBgColor(aColor);
  } else if (reg.test(that)) {
    // 处理十六进制色值
    var sColor = colorValue.toLowerCase();
    if (sColor && reg.test(sColor)) {
      if (sColor.length === 4) {
        var sColorNew = "#";
        for (var i = 1; i < 4; i += 1) {
          sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
        }
        sColor = sColorNew;
      }
      //处理六位的颜色值
      var sColorChange = [];
      for (var i = 1; i < 7; i += 2) {
        sColorChange.push(parseInt("0x" + sColor.slice(i, i + 2)));
      }
      return resBgColor(sColorChange);
    } else {
      return false;
    }
  } else {
    return false;
  }
}

/**
 * 图片转换base64（压缩、方向旋转）
 * @param {blob} file文件对象
 * @param {func} callBack 成功回调函数，参数为压缩后的二进制文件流
 * @param {object} params 压缩文件参数 {可以根据 isBase64 == true 返回 base64格式， 否则返回 blob 格式内容
 */

function imageCompression(file) {
  var callBack = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
  var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    width: 500,
    LIMIT_SIZE: 2 * 1024 * 2014,
    isBase64: true
  };
  var compressCount = 0;
  var width = params.width,
    LIMIT_SIZE = params.LIMIT_SIZE,
    isBase64 = params.isBase64;
  var orientation = _exifJs.default.getTag(file, "Orientation") || 1;
  // 图片压缩出错函数
  var errorFn = function errorFn(e) {
    console.error("图片压缩出问题了", e);
    callBack(file);
  };
  if (!window.FileReader) {
    console.error("浏览器不支持 window.FileReader 方法哦");
    callBack(file);
  } else {
    try {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        var img = new Image();
        img.src = reader.result;
        img.onload = function () {
          try {
            var canvas = document.createElement("canvas");
            var context = canvas.getContext("2d");
            var canvasWidth = width;
            var canvasHeight = canvasWidth / (img.width / img.height);
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;
            var angle = 0;
            switch (orientation) {
              case 1:
                break;
              case 6:
                angle = 90 * Math.PI / 180;
                canvas.width = canvasHeight;
                canvas.height = canvasWidth;
                context.rotate(angle);
                context.translate(0, -canvas.width);
                break;
              case 8:
                angle = 270 * Math.PI / 180;
                canvas.width = canvasHeight;
                canvas.height = canvasWidth;
                context.rotate(angle);
                context.translate(-canvas.height, 0);
                break;
              case 3:
                angle = 180 * Math.PI / 180;
                canvas.width = canvasWidth;
                canvas.height = canvasHeight;
                context.rotate(angle);
                context.translate(-canvas.width, -canvas.height);
                break;
              default:
                break;
            }
            context.drawImage(img, 0, 0, canvasWidth, canvasHeight);
            context.setTransform(1, 0, 0, 1, 0, 0);
            canvas.toBlob(function (blob) {
              if (blob.size > LIMIT_SIZE) {
                compressCount += 1;
                util.imageCompression(blob, callBack);
              } else {
                compressCount = 0;
                if (isBase64) {
                  // conversion(blob, callBack);
                  callBack(canvas.toDataURL("image/png", 1));
                } else {
                  callBack(blob);
                }
              }
            }, "image/jpeg", 0.9 - compressCount * 0.1);
          } catch (error) {
            errorFn(error);
          }
        };
      };
      reader.onerror = function (error) {
        errorFn(error);
      };
    } catch (error) {
      errorFn(error);
    }
    // 转为base64
  }
}

// 复制
function copyText() {
  var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "#";
  if (navigator.clipboard) {
    // clipboard api 复制
    navigator.clipboard.writeText(text);
  } else {
    var textarea = document.createElement("textarea");
    document.body.appendChild(textarea);
    // 隐藏此输入框
    textarea.style.position = "fixed";
    textarea.style.clip = "rect(0 0 0 0)";
    textarea.style.top = "10px";
    // 赋值
    textarea.value = text;
    // 选中
    textarea.select();
    // 复制
    document.execCommand("copy", true);
    // 移除输入框
    document.body.removeChild(textarea);
  }
  _elementUi.Message.success("ID ".concat(text, " \u590D\u5236\u6210\u529F"));
}

/**
 * 根据身份证号码获取出生日期
 * @param idCard
 */
function getBirth(idCard) {
  var birthday = '';
  if (idCard != null && idCard != '') {
    if (idCard.length == 15) {
      birthday = '19' + idCard.slice(6, 12);
    } else if (idCard.length == 18) {
      birthday = idCard.slice(6, 14);
    }
    birthday = birthday.replace(/(.{4})(.{2})/, '$1-$2-');
    //通过正则表达式来指定输出格式为:1990-01-01
  }
  return birthday;
}

/**
 * 根据身份证号码获取性别
 * @param idCard
 */
function getSex(idCard) {
  var sexStr = '';
  if (parseInt(idCard.slice(-2, -1)) % 2 == 1) {
    sexStr = 'M';
  } else {
    sexStr = 'W';
  }
  return sexStr;
}

/** transfer time */
var transferTime = exports.transferTime = function transferTime() {
  var milliseconds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var type = arguments.length > 1 ? arguments[1] : undefined;
  // get duration
  var getDurations = function getDurations(milliseconds) {
    // days
    var days = milliseconds / 1000 / 60 / 60 / 24;
    var daysRound = Math.floor(days);
    var daysStr = "".concat(daysRound > 0 ? "".concat(daysRound, "\u5929") : '');
    // hours
    var hours = milliseconds / 1000 / 60 / 60 - 24 * daysRound;
    var hoursRound = Math.floor(hours);
    var hoursStr = "".concat(hoursRound > 0 ? "".concat(hoursRound, "\u65F6") : '');
    // minutes
    var minutes = milliseconds / 1000 / 60 - 24 * 60 * daysRound - 60 * hoursRound;
    var minutesRound = Math.floor(minutes);
    var minutesStr = "".concat(minutesRound > 0 ? "".concat(minutesRound, "\u5206") : '');
    // seconds
    var seconds = milliseconds / 1000 - 24 * 60 * 60 * daysRound - 60 * 60 * hoursRound - 60 * minutesRound;
    var secondsRound = Math.floor(seconds);
    var secondsStr = "".concat(secondsRound > 0 ? "".concat(secondsRound, "\u79D2") : '');
    var str = "".concat(daysStr).concat(hoursStr).concat(minutesStr).concat(secondsStr);
    return str;
  };
  // get date
  var getDate = function getDate(milliseconds) {
    var date = new Date(milliseconds / 1);
    // year
    var year = date.getFullYear();
    // month
    var month = date.getMonth() + 1;
    month = month < 10 ? '0' + month : month;
    // day
    var day = date.getDate();
    day = day < 10 ? '0' + day : day;
    // hour
    var hours = date.getHours();
    hours = hours < 10 ? '0' + hours : hours;
    // minutes
    var minutes = date.getMinutes();
    minutes = minutes < 10 ? '0' + minutes : minutes;
    // seconds
    var seconds = date.getSeconds();
    seconds = seconds < 10 ? '0' + seconds : seconds;
    return "".concat(year, "-").concat(month, "-").concat(day, " ").concat(hours, ":").concat(minutes, ":").concat(seconds);
  };
  if (type === 'duration') {
    return getDurations(milliseconds);
  }
  if (type === 'date') {
    return getDate(milliseconds);
  }
};

// 对象数组去重
function uniqueByField(array, field) {
  return array.reduce(function (acc, cur) {
    if (!acc.find(function (item) {
      return item[field] === cur[field];
    })) {
      acc.push(cur);
    }
    return acc;
  }, []);
}

/**
 * 判断数据是不是普通类型
 * @param data  需要判断的数据
 * @returns  是否是普通类型数据
 */
function isPrimitive(data) {
  var type = (0, _typeof2.default)(data);
  return type === 'string' || type === 'number' || type === 'boolean' || data === null || type === 'undefined';
}

/**
 *
 * @param data 需要脱敏数据
 * @param config 脱敏函数配置
 * @returns
 */
function desensitizationData(data) {
  if (Array.isArray(data)) {
    data.forEach(function (item) {
      desensitizationData(item);
    });
  } else if ((0, _typeof2.default)(data) === 'object' && data !== null) {
    for (var key in data) {
      // 如果是对象 并且是普通类型 就判断是不是包含脱敏字段，包含就进行处理
      if (isPrimitive(data[key]) && key.indexOf('Encrypted') != -1) {
        data["".concat(key.replace('Encrypted', ''), "Secret")] = data[key.replace('Encrypted', '')];
        data[key.replace('Encrypted', '')] = (0, _aes.decryptByAES)(data[key]);
      } else {
        desensitizationData(data[key]);
      }
    }
  }
  return data;
}