import _objectSpread from "/Users/sophiechou/Desktop/whenyoung/\u5609\u5584\u51CF\u707E\u793E\u533A/admin-web/node_modules/.pnpm/@babel+runtime@7.24.8/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout";
import db from "@/utils/localstorage";
import store from "@/store/index";
import loginApi from "@/api/Login.js";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
Vue.use(Router);

// let homeUrl = db.get("HOME_URL", "").homeUrl;
var constRouter = [{
  path: "/redirect",
  component: Layout,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component(resolve) {
      return require(["@/views/redirect/index"], resolve);
    }
  }]
}, {
  path: "/404",
  component: function component(resolve) {
    return require(["@/views/error-page/404"], resolve);
  },
  hidden: true
}, {
  path: "/login",
  name: "登录页",
  component: function component(resolve) {
    return require(["@/views/login/index"], resolve);
  }
}, {
  path: "/login/login1",
  name: "登录页1",
  component: function component(resolve) {
    return require(["@/views/login/login1"], resolve);
  }
}, {
  path: "/middle",
  name: "Middle",
  component: function component(resolve) {
    return require(["@/views/middle/index"], resolve);
  }
}, {
  path: "/BMapDraw",
  name: "BMapDraw",
  component: function component(resolve) {
    return require(["@/views/BMapDraw/index"], resolve);
  }
}, {
  path: "/favorites",
  name: "Favorites",
  component: function component(resolve) {
    return require(["@/views/favorites/index"], resolve);
  }
}, {
  path: "/",
  component: Layout,
  redirect: "/dashboard/index"
  // redirect: to => {
  //   if (homeUrl) {
  //     return homeUrl
  //   } else {
  //     return '/dashboard'
  //   }
  // }
}, {
  path: "/dashboard",
  component: Layout,
  redirect: "/dashboard/index",
  hidden: true,
  children: [{
    path: "index",
    component: function component(resolve) {
      return require(["@/views/dashboard/index"], resolve);
    },
    name: "Dashboard",
    meta: {
      title: "dashboard",
      icon: "dashboard",
      noCache: true
    }
  }]
}, {
  path: "/profile",
  component: Layout,
  redirect: "/profile/index",
  hidden: true,
  children: [{
    path: "index",
    component: function component(resolve) {
      return require(["@/views/profile/index"], resolve);
    },
    name: "Profile",
    meta: {
      title: "profile",
      icon: "user",
      noCache: true
    }
  }]
}, {
  path: "/resources",
  component: Layout,
  redirect: "/resources/msg",
  hidden: true,
  alwaysShow: true,
  meta: {
    title: "资源中心",
    icon: "",
    noCache: true,
    breadcrumb: true
  },
  children: [{
    hidden: true,
    alwaysShow: false,
    path: "/resources/msg/edit",
    component: function component(resolve) {
      return require(["@/views/azcs/resources/msg/edit"], resolve);
    },
    name: "发送消息",
    meta: {
      title: "发送消息",
      icon: "",
      noCache: true,
      breadcrumb: true
    }
  }, {
    hidden: true,
    path: "/resources/sms/edit",
    component: function component(resolve) {
      return require(["@/views/azcs/resources/sms/edit"], resolve);
    },
    name: "smsEdit",
    meta: {
      title: "发送短信",
      icon: "",
      noCache: true
    }
  }]
}, {
  path: "/message",
  component: Layout,
  redirect: "/message/smsTask",
  hidden: true,
  alwaysShow: true,
  meta: {
    title: "短信群发",
    icon: "",
    noCache: true,
    breadcrumb: true
  },
  children: [{
    hidden: true,
    alwaysShow: false,
    path: "/message/smsTask/create",
    component: function component(resolve) {
      return require(["@/views/azcs/community/smsTask/create"], resolve);
    },
    name: "smsTaskCreate",
    meta: {
      title: "短信群发",
      icon: "",
      noCache: true,
      breadcrumb: true
    }
  }]
}, {
  path: "/error",
  component: Layout,
  redirect: "noRedirect",
  name: "ErrorPages",
  meta: {
    title: "errorPages",
    icon: "404"
  },
  children: [{
    path: "404",
    component: function component(resolve) {
      return require(["@/views/error-page/404"], resolve);
    },
    name: "Page404",
    meta: {
      title: "page404",
      noCache: true
    }
  }]
}];
var router = new Router({
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constRouter
});
var whiteList = ["/login", "/login/login1", "/middle", "/favorites", "/BMapDraw"];
// 异步的路由信息， 同时，左侧菜单也会根据这个来显示
var asyncRouter;

// 导航守卫，渲染动态路由
router.beforeEach(function (to, from, next) {
  NProgress.start();
  if (whiteList.indexOf(to.path) !== -1) {
    next();
  } else {
    var token = db.get("TOKEN", "");
    var user = db.get("USER");
    var userRouter = db.get("USER_ROUTER", ""); //缓存中的路由
    if (token && token.length && user) {
      if (!asyncRouter) {
        if (!userRouter) {
          loginApi.getRouter({}).then(function (response) {
            var res = response.data;
            asyncRouter = res.data;
            if (!(asyncRouter && asyncRouter.length > 0)) {
              asyncRouter = [];
            }
            asyncRouter.push({
              alwaysShow: false,
              component: "error-page/404",
              hidden: false,
              name: "404",
              path: "*"
            });
            store.commit("account/setRoutes", asyncRouter);
            go(to, next);
          });
        } else {
          asyncRouter = userRouter;
          go(to, next);
        }
      } else {
        if (to.path == "/supplies/out/detail") {
          // to.meta.title = db.get('sRouteTitle', '出库单详情')
        }
        next();
      }
    } else {
      if (to.path === "/login") {
        next();
      } else {
        db.clear();
        next("/login");
      }
    }
  }
});
router.afterEach(function () {
  NProgress.done();
});
function go(to, next) {
  asyncRouter = filterAsyncRouter(asyncRouter);
  router.addRoutes(asyncRouter);
  next(_objectSpread(_objectSpread({}, to), {}, {
    replace: true
  }));
}
function filterAsyncRouter(routes, parent) {
  return routes.filter(function (route) {
    var component = route.component;
    if (component) {
      if (route.component === "Layout") {
        if (route.children && route.children.length > 0 && parent) {
          route.component = function (resolve) {
            require(["@/components/RouterWrap/RouterWrap.vue"], resolve);
          };
        } else {
          route.component = Layout;
        }
      } else if (typeof component === "string") {
        route.component = view(component);
      }
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, route);
      }
      return true;
    }
  });
}
function view(path) {
  return function (resolve) {
    require(["@/views/".concat(path, ".vue")], resolve);
  };
}
export default router;