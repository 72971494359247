"use strict";

var _interopRequireWildcard = require("/Users/sophiechou/Desktop/whenyoung/\u5609\u5584\u51CF\u707E\u793E\u533A/admin-web/node_modules/.pnpm/@babel+runtime@7.24.8/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/Users/sophiechou/Desktop/whenyoung/\u5609\u5584\u51CF\u707E\u793E\u533A/admin-web/node_modules/.pnpm/@babel+runtime@7.24.8/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("/Users/sophiechou/Desktop/whenyoung/\u5609\u5584\u51CF\u707E\u793E\u533A/admin-web/node_modules/.pnpm/core-js@3.37.1/node_modules/core-js/modules/es.array.iterator.js");
require("/Users/sophiechou/Desktop/whenyoung/\u5609\u5584\u51CF\u707E\u793E\u533A/admin-web/node_modules/.pnpm/core-js@3.37.1/node_modules/core-js/modules/es.promise.js");
require("/Users/sophiechou/Desktop/whenyoung/\u5609\u5584\u51CF\u707E\u793E\u533A/admin-web/node_modules/.pnpm/core-js@3.37.1/node_modules/core-js/modules/es.object.assign.js");
require("/Users/sophiechou/Desktop/whenyoung/\u5609\u5584\u51CF\u707E\u793E\u533A/admin-web/node_modules/.pnpm/core-js@3.37.1/node_modules/core-js/modules/es.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _lang = _interopRequireDefault(require("./lang"));
require("./icons");
require("./utils/error-log");
var _request = _interopRequireDefault(require("@/utils/request"));
var filters = _interopRequireWildcard(require("./filters"));
var _permissionDirect = require("./utils/permissionDirect");
var _vueSimpleUploader = _interopRequireDefault(require("vue-simple-uploader"));
require("e-icon-picker/dist/symbol.js");
require("e-icon-picker/dist/index.css");
require("font-awesome/css/font-awesome.min.css");
require("element-ui/lib/theme-chalk/icon.css");
var _iconfont = _interopRequireDefault(require("./styles/iconfont.json"));
require("./styles/iconfont.css");
var _eIconPicker = _interopRequireWildcard(require("e-icon-picker"));
var _getSvg = _interopRequireDefault(require("e-icon-picker/dist/getSvg"));
var _print = _interopRequireDefault(require("@/utils/print"));
var _vueJsonExcel = _interopRequireDefault(require("vue-json-excel"));
require("element-ui/lib/theme-chalk/index.css");
require("@/styles/index.scss");
// a modern alternative to CSS resets

// internationalization
// icon
// error log

// global filters

//基本彩色图标库
// 基本样式，包含基本图标
//font-awesome 图标库
//element-ui 图标库
//自己的图标信息
//自己的图标样式

// import './styles/element-variables.scss'

// global css
_vue.default.component("downloadExcel", _vueJsonExcel.default);
var forIconfont = (0, _eIconPicker.analyzingIconForIconfont)(_iconfont.default);
//全局删除增加图标
_vue.default.use(_eIconPicker.default, {
  FontAwesome: true,
  ElementUI: true,
  eIcon: true,
  //自带的图标，来自阿里妈妈
  eIconSymbol: true,
  //是否开启彩色图标
  addIconList: forIconfont.list,
  removeIconList: []
});
_eIconPicker.iconList.addIcon(_getSvg.default);
// iconList.removeIcon(["el-icon-s-ticket"]);

var Plugins = [_permissionDirect.hasPermission, _permissionDirect.hasNoPermission, _permissionDirect.hasAnyPermission, _permissionDirect.hasAnyRole, _permissionDirect.hasNoRole, _permissionDirect.hasRole];
Plugins.map(function (plugin) {
  _vue.default.use(plugin);
});
_vue.default.use(_elementUi.default, {
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  }
});
_vue.default.use(_vueSimpleUploader.default);
_vue.default.use(_print.default);
_vue.default.prototype.$post = _request.default.post;
_vue.default.prototype.$get = _request.default.get;
_vue.default.prototype.$put = _request.default.put;
_vue.default.prototype.$delete = _request.default.delete;
_vue.default.prototype.$download = _request.default.download;
_vue.default.prototype.$upload = _request.default.upload;
_vue.default.prototype.$login = _request.default.login;
// 通用loading
_vue.default.prototype.openLoading = function () {
  var loading = this.$loading({
    lock: true,
    body: true
  });
  setTimeout(function () {
    loading.close();
  }, 10000);
  return loading;
};
// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  render: function render(h) {
    return h(_App.default);
  }
});