"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.json.stringify.js");
require("core-js/modules/es.object.keys.js");
var db = {
  save: function save(key, value) {
    // if (value) {
    var projectName = process.env.VUE_APP_PROJECT_NAME;
    localStorage.setItem(projectName + "_" + key, JSON.stringify(value));
    // } else {
    //   console.log("set 出错： key=" + key + ',value=' + value)
    // }
  },
  get: function get(key) {
    var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var projectName = process.env.VUE_APP_PROJECT_NAME;
    try {
      return JSON.parse(localStorage.getItem(projectName + "_" + key)) || defaultValue;
    } catch (err) {
      return defaultValue;
    }
  },
  remove: function remove(key) {
    var projectName = process.env.VUE_APP_PROJECT_NAME;
    localStorage.removeItem(projectName + "_" + key);
  },
  clear: function clear() {
    // localStorage.clear()

    // 为了不同路径能在同一个域下 独立使用， 清除缓存时，需要区分来

    var projectName = process.env.VUE_APP_PROJECT_NAME;
    for (var key in localStorage) {
      if (key.indexOf(projectName) !== -1) {
        console.log(key);
        localStorage.removeItem(key);
      }
    }
  }
};
var _default = exports.default = db;