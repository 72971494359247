"use strict";

var _interopRequireDefault = require("/Users/sophiechou/Desktop/whenyoung/\u5609\u5584\u51CF\u707E\u793E\u533A/admin-web/node_modules/.pnpm/@babel+runtime@7.24.8/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.json.stringify.js");
var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));
var _default = exports.default = {
  namespaced: true,
  state: {
    supplierList: [],
    routeTitle: '出库单详情',
    orgTree: [],
    locationDetail: []
  },
  mutations: {
    setSupplier: function setSupplier(state) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      state.supplierList = data;
      _localstorage.default.save('supplierList', JSON.stringify(data));
    },
    setRouteTitle: function setRouteTitle(state) {
      var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '出库单详情';
      this.state.routeTitle = title;
      _localstorage.default.save('sRouteTitle', title);
    },
    setOgrTree: function setOgrTree(state) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      state.orgTree = data;
      _localstorage.default.save('intoOrgTree', JSON.stringify(data));
    },
    setLocationDetail: function setLocationDetail(state) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      state.locationDetail = data;
      _localstorage.default.save('locationDetail', JSON.stringify(data));
    }
  }
};