import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/web.atob.js";
import "core-js/modules/web.dom-exception.constructor.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.dom-exception.to-string-tag.js";
import CryptoJS from "crypto-js";
import { encrypt, decrypt } from "crypto-js/aes";
import { parse } from "crypto-js/enc-utf8";
import pkcs7 from "crypto-js/pad-pkcs7";
import md5 from "crypto-js/md5";
import UTF8 from "crypto-js/enc-utf8";
import Base64 from "crypto-js/enc-base64";
var cryptoCipherArr = atob("S2V5SnNBbmxhbnlqQDAyNC1JdldoZW55b3VuZ0AyMDI0Cg==", "base64").split("-");
var cacheCipher = {
  key: parse(cryptoCipherArr[0]),
  iv: parse(cryptoCipherArr[1])
};
function getOptions() {
  return {
    mode: CryptoJS.mode.CBC,
    padding: pkcs7,
    iv: cacheCipher.iv
  };
}
//加密
export function encryptByAES(cipherText) {
  return encrypt(cipherText, cacheCipher.key, getOptions()).toString();
}
// 解密
export function decryptByAES(cipherText) {
  return cipherText && decrypt(cipherText, cacheCipher.key, getOptions()).toString(UTF8);
}
export function encryptByBase64(cipherText) {
  return UTF8.parse(cipherText).toString(Base64);
}
export function decodeByBase64(cipherText) {
  return Base64.parse(cipherText).toString(UTF8);
}
export function encryptByMd5(password) {
  return md5(password).toString();
}