"use strict";

var _interopRequireDefault = require("/Users/sophiechou/Desktop/whenyoung/\u5609\u5584\u51CF\u707E\u793E\u533A/admin-web/node_modules/.pnpm/@babel+runtime@7.24.8/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));
var _default = exports.default = {
  namespaced: true,
  state: {
    refreshToken: _localstorage.default.get("REFRESH_TOKEN"),
    token: _localstorage.default.get("TOKEN", ""),
    tenant: _localstorage.default.get("TENANT", ""),
    expireTime: _localstorage.default.get("EXPIRE_TIME", 0),
    user: _localstorage.default.get("USER") || {},
    permissions: _localstorage.default.get("PERMISSIONS") || [],
    authorityResource: _localstorage.default.get("AUTHORITY_RESOURCE") || {
      enabled: true,
      caseSensitive: false,
      resourceList: [],
      roleList: []
    },
    routes: _localstorage.default.get("USER_ROUTER") || [],
    homeUrl: _localstorage.default.get("HOME_URL") || ""
  },
  mutations: {
    setTenant: function setTenant(state, val) {
      _localstorage.default.save("TENANT", val);
      state.accessToken = val;
    },
    setRefreshToken: function setRefreshToken(state, val) {
      _localstorage.default.save("REFRESH_TOKEN", val);
      state.refreshToken = val;
    },
    setToken: function setToken(state, val) {
      _localstorage.default.save("TOKEN", val);
      state.token = val;
    },
    setExpireTime: function setExpireTime(state, val) {
      _localstorage.default.save("EXPIRE_TIME", val);
      state.expireTime = val;
    },
    setUser: function setUser(state, val) {
      _localstorage.default.save("USER", val);
      state.user = val;
    },
    setPermissions: function setPermissions(state, val) {
      _localstorage.default.save("PERMISSIONS", val);
      state.permissions = val;
    },
    setAuthorityResource: function setAuthorityResource(state, val) {
      _localstorage.default.save("AUTHORITY_RESOURCE", val);
      state.authorityResource = val;
    },
    setRoutes: function setRoutes(state, val) {
      _localstorage.default.save("USER_ROUTER", val);
      state.routes = val;
    },
    setHomeUrl: function setHomeUrl(state, val) {
      _localstorage.default.save("HOME_URL", val);
      state.homeUrl = val;
    }
  }
};