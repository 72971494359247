"use strict";

var _interopRequireWildcard = require("/Users/sophiechou/Desktop/whenyoung/\u5609\u5584\u51CF\u707E\u793E\u533A/admin-web/node_modules/.pnpm/@babel+runtime@7.24.8/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/Users/sophiechou/Desktop/whenyoung/\u5609\u5584\u51CF\u707E\u793E\u533A/admin-web/node_modules/.pnpm/@babel+runtime@7.24.8/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.decodeByBase64 = decodeByBase64;
exports.decryptByAES = decryptByAES;
exports.encryptByAES = encryptByAES;
exports.encryptByBase64 = encryptByBase64;
exports.encryptByMd5 = encryptByMd5;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/web.atob.js");
require("core-js/modules/web.dom-exception.constructor.js");
require("core-js/modules/web.dom-exception.stack.js");
require("core-js/modules/web.dom-exception.to-string-tag.js");
var _cryptoJs = _interopRequireDefault(require("crypto-js"));
var _aes = require("crypto-js/aes");
var _encUtf = _interopRequireWildcard(require("crypto-js/enc-utf8"));
var _padPkcs = _interopRequireDefault(require("crypto-js/pad-pkcs7"));
var _md = _interopRequireDefault(require("crypto-js/md5"));
var _encBase = _interopRequireDefault(require("crypto-js/enc-base64"));
var cryptoCipherArr = atob("S2V5SnNBbmxhbnlqQDAyNC1JdldoZW55b3VuZ0AyMDI0Cg==", "base64").split("-");
var cacheCipher = {
  key: (0, _encUtf.parse)(cryptoCipherArr[0]),
  iv: (0, _encUtf.parse)(cryptoCipherArr[1])
};
function getOptions() {
  return {
    mode: _cryptoJs.default.mode.CBC,
    padding: _padPkcs.default,
    iv: cacheCipher.iv
  };
}
//加密
function encryptByAES(cipherText) {
  return (0, _aes.encrypt)(cipherText, cacheCipher.key, getOptions()).toString();
}
// 解密
function decryptByAES(cipherText) {
  return (0, _aes.decrypt)(cipherText, cacheCipher.key, getOptions()).toString(_encUtf.default);
}
function encryptByBase64(cipherText) {
  return _encUtf.default.parse(cipherText).toString(_encBase.default);
}
function decodeByBase64(cipherText) {
  return _encBase.default.parse(cipherText).toString(_encUtf.default);
}
function encryptByMd5(password) {
  return (0, _md.default)(password).toString();
}